import "assets/css/splash.css";

const ScreenLoader = () => {

  return (
			<section style={{height: '100vh',
				width : '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: "#001e31",
				flexDirection : "column",
				overflow : "hidden"}}>
				<div className="loader">
					<div className="ball moving"></div>
					<div className="balls">
						<div className="ball"></div>
						<div className="ball"></div>
						<div className="ball"></div>
						<div className="ball"></div>
						<div className="ball moving"></div>
					</div>
				</div>
				<figure style={{margin:0}}>
					<svg className="splashLoader">
						<filter id="goo">
							<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
							<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
						</filter>
					</svg>
					<figcaption  style={{textAlign:'center'}}><h3 className="typewriter" >Loading...</h3></figcaption>
				</figure>
			</section>
  )
}

export default ScreenLoader;