import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import authReduder  from "context/auth/authSlice";



import { apiSlice } from "../api/apiSlice";



 /* eslint-disable */
export const store = configureStore({
    reducer : {
        [apiSlice.reducerPath] : apiSlice.reducer,
        auth   : authReduder,
        
    },
    middleware : getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            apiSlice.middleware,
        ]),
    
    devTools : true
    
})
setupListeners(store.dispatch)