import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import  { setCredentials, logout } from "../context/auth/authSlice";

// const platform = window.navigator?.userAgentData?.platform || window.navigator;
// 'android' ? 'http://10.0.2.2' : 'http://localhost'

// if (location.protocol !== 'https:') {
//     location.replace(`https:${location.href.substring(location.protocol.length)}`);
// }

// eslint-disable-next-line no-restricted-globals
const API_URL = `${location.protocol}//bse-dz.com/bse`; // location.protocol !== 'https:' https://bse-dz.com/bse

const baseQuery = fetchBaseQuery({
    baseUrl : API_URL,
    credentials : 'include',
    prepareHeaders : (headers, {getState}) =>{
        const token = getState().auth.accessToken;
        if(token){
            headers.set("Authorization",`Bearer ${token}`);
        }
        return headers
    }
});


const baseQueryWithReAuth = async (args,api,extraOptions)=>{
    let result = await baseQuery(args,api,extraOptions);
    if( result?.error?.originalStatus === 403 || result?.error?.originalStatus === 401 || result?.error?.status === 401 ){
        // sending the refresh token to get access authorizations 
        const refreshResult = await baseQuery('/refresh',api, extraOptions);
        if(refreshResult?.data){
            api.dispatch(setCredentials({...refreshResult.data}));
            // retry the original query with new token
            result = await baseQuery (args,api,extraOptions);
        }else{
            /*eslint-disable */
            if( 0 === 1 ) api.dispatch(logout());
        }
    }
    return result;
}
 /* eslint-disable */
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery : baseQueryWithReAuth,
    tagTypes: ['User', 'Page',  'Config', 'Chart', 'Review','Icon'],
     /* eslint-disable */
    endpoints : builder =>({})
})
